import type {
  ListReturnValue,
  SingularReturnValue,
} from "@aws-amplify/data-schema-types";
import { throwFirstError } from "./throwFirstError.js";

export function parseResponse<
  V extends SingularReturnValue<any> | ListReturnValue<any>,
>(response: Awaited<V>): Awaited<V>["data"] {
  const { errors, data } = response;
  throwFirstError(errors);
  return data;
}
