<script lang="ts">
    import GifMoji from "./GifMoji.svelte";
</script>

<style>
    p {
        width: 80%;
    }
</style>
<div class="flex flex-col gap-4 items-center">
    <GifMoji correct={false}/>
    <p class="font-bold text-center"><slot/></p>
</div>
