export enum ModelName {
  Archive = "Archive",
  Gif = "Gif",
  Contact = "Contact",
  EmailEvent = "EmailEvent",
  Publication = "Publication",
  PublicationReteyner = "PublicationReteyner",
  Reteyner = "Reteyner",
  Topic = "Topic",
  Question = "Question",
  Answer = "Answer",
  Submission = "Submission",
  Enrollment = "Enrollment",
  GroupEnrollment = "GroupEnrollment",
  Test = "Test",
  Fact = "Fact",
  QuizTemplate = "QuizTemplate",
  ReteynerQuizTemplate = "ReteynerQuizTemplate",
  Group = "Group",
  ContactGroup = "ContactGroup",
  Organisation = "Organisation",
  Membership = "Membership",
  Preferences = "Preferences",
  Report = "Report",
}
