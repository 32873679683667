<script lang="ts">
    import { ReteynSchema } from "../schema";
    import ValidityMessage from "./ValidityMessage.svelte";
    import GifMoji from "./GifMoji.svelte";
    import CentralContent from "./CentralContent.svelte";
    export let submission: ReteynSchema["submit"]["returnType"];

    let showAnswer: boolean = false;
</script>

<CentralContent>
    {#if showAnswer}
        <ValidityMessage answer={{ correct: !!submission?.correct }} />
        <p>
            {submission?.response}
        </p>
        <p class="font-bold mt-10">Fun Fact:</p>
        <p>
            {submission?.fact}
        </p>
    {:else}
        <GifMoji correct={!!submission?.correct}/>
        <ValidityMessage answer={{ correct: !!submission?.correct }} />
        <button class="btn btn-primary" on:click={() => (showAnswer = true)}>
            Tell me why
        </button>
    {/if}
</CentralContent>

<style>
    p {
        width: 80%;
    }
</style>
