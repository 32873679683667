import {
  retrieveTokens,
  TokensFromStorage,
} from "amazon-cognito-passwordless-auth/storage";

export async function retrieveValidTokens(): Promise<
  TokensFromStorage | undefined
> {
  const tokens = await retrieveTokens();
  if (tokens && tokens.expireAt && tokens.expireAt > new Date()) {
    return tokens;
  }
}
