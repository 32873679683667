<script lang="ts">
    import { resolveHomeSite } from "../config";
    import logo from "../reteyn-components/Reteyn_WhiteBG.png";
    const homeSite = resolveHomeSite();
</script>

<style>
    .logo {
        width: 118px;
        height: 42px;
    }
</style>

<div class="full-height w-full flex flex-col items-center">
    <div class="w-full max-w-md grow flex flex-col items-center justify-center">
        <slot/>
    </div>
    <div class="footer footer-center py-10">
        <a target="_blank" href="{homeSite}" >
            <img class="logo" src={logo} alt="Reteyn logo" aria-label="Reteyn logo"/>
        </a>
    </div>
</div>
