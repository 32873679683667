<script lang="ts">
    import { ErrorMessage, type ReteynSchema } from "../schema";
    import { Loading } from "../reteyn-components";
    import LinkError from "./LinkError.svelte";
    import Response from "./Response.svelte";
    import { ApiClient } from "./ApiClient";
    import { magicLinkExpiryHours } from "../config";
    import { clientStore } from "./clientStore.js";
    import { parseReturnValue } from "../dao";
    import { EventName, Tracker } from "../analytics";

    export let testId: string;
    export let answerId: string;
    export let submitEndpoint: string;
    export let tracker: Tracker;
    let submissionPromise:
        | Promise<ReteynSchema["submit"]["returnType"]>
        | undefined;

    const knownErrors: Record<ErrorMessage, string> = {
        [ErrorMessage.AlreadySubmitted]:
            "Oops! 🤕 An answer has already been submitted for this quiz. 📮",
        [ErrorMessage.Expired]: `Oops! 🤕 Quizzes expire after ${magicLinkExpiryHours} hours. ⌛`,
    };

    const invalidLinkMessage = "Invalid link.";
    function toDisplayError(message: string): string {
        return knownErrors[message as ErrorMessage] || invalidLinkMessage;
    }
    async function submit(
        params: ReteynSchema["submit"]["args"],
    ): Promise<ReteynSchema["submit"]["returnType"]> {
        const submitter = new ApiClient<
            ReteynSchema["submit"]["args"],
            ReteynSchema["submit"]["returnType"]
        >(submitEndpoint);
        return submitter.post(params);
    }

    async function identifyUser(testId: string): Promise<void> {
        const client = $clientStore;
        const info = await parseReturnValue(
            client.queries.testInfo({ testId }),
        );
        if (info) {
            await tracker.identify(
                testId,
                {
                    organisationName: info.organisationName
                },
            );
        }
    }

    async function trackSubmission(
        promise: Promise<ReteynSchema["submit"]["returnType"]>,
    ) {
        try {
            const response = await promise;
            await tracker.track(EventName.AnswerSubmitted, {
                testId,
                answerId,
                correct: response?.correct,
                answer: response?.answer,
            });
        } catch (err) {
            // Re-throw the error so that it's logged by the tracker at the top-level
            throw err;
        }
    }
    $: identifyUser(testId);
    $: submissionPromise = submit({ testId, answerId });
    $: trackSubmission(submissionPromise);
</script>

{#await submissionPromise}
    <Loading></Loading>
{:then submission}
    {#if submission}
        <Response {submission} />
    {:else}
        <LinkError>{invalidLinkMessage}</LinkError>
    {/if}
{:catch err}
    {@const message = toDisplayError(err.message)}
    <LinkError>{message}</LinkError>
{/await}
