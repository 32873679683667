import { AnalyticsPlugin } from "analytics";
import { BasePayload } from "./BasePayload.js";
import { EventName } from "./EventName.js";
import { EventType } from "./EventType.js";
import { Plugin } from "./Plugin.js";
import { record } from "aws-amplify/analytics/kinesis-firehose";
import merge from "lodash/merge.js";
import { getTimeZone, getBrowserLocale } from "analytics-utils";
import { Traits } from "./Traits.js";

export class FirehoseAnalyticsPlugin implements Plugin, AnalyticsPlugin {
  name = "firehose-analytics-plugin";
  constructor(private options: { site?: string; streamName: string }) {}
  [x: string]: unknown;

  record(payload: BasePayload<any, any>) {
    const site = this.options.site || location.hostname;
    record({
      streamName: this.options.streamName,
      data: {
        ...payload,
        site,
      },
    });
  }

  page(event: {
    payload: BasePayload<
      EventType.Page,
      {
        hash: string;
        height: number;
        path: string;
        search: string;
        title: string;
        url: string;
        width: number;
      }
    >;
  }) {
    this.record(
      merge(event.payload, {
        properties: {
          userAgent: navigator.userAgent,
          locale: getBrowserLocale(),
          timeZone: getTimeZone(),
        },
      }),
    );
  }
  track(event: {
    payload: { event: EventName } & BasePayload<
      EventType.Track,
      | {}
      | { innerText: string; ariaLabel: string; tagName: Element["tagName"] }
      | { message: string }
    >;
  }) {
    this.record(event.payload);
  }
  identify(event: {
    payload: BasePayload<EventType.Identify, undefined> & {
      traits: Traits;
    };
  }) {
    this.record(event.payload);
  }
}
