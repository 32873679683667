<script lang="ts">
  import { Route as AppRoute, Config } from "../config";
  import Home from "./Home.svelte";
  import Submit from "./Submit.svelte";
  import { Router, Route } from "svelte-navigator";
  import Main from "./Main.svelte";
  import Confirmed from "./Confirmed.svelte";
  import Analytics from "analytics";
  import { onMount } from "svelte";
  import googleAnalytics from '@analytics/google-analytics'
  import {EventName, FirehoseAnalyticsPlugin, Tracker} from "../analytics";

  export let config: Config;

  const analytics = Analytics({
    app: "student-dashboard",
    version: 1,
    plugins: [
      googleAnalytics({
        measurementIds: [config.googleMeasurementId]
      }),
      new FirehoseAnalyticsPlugin({
        streamName: config.analyticsStreamName
      }),
    ],
  });
  const tracker = new Tracker(analytics);
  onMount(() => tracker.page({
    // Exlude hash to avoid tokens being logged
    hash: ""
  }));

  async function trackClick(target: EventTarget | null) {
    const elementTarget = target as HTMLElement | null;
    await tracker.track(EventName.Click, {
      innerText: elementTarget?.innerText,
      ariaLabel: elementTarget?.ariaLabel as string,
      tagName: elementTarget?.tagName
    })
  }

</script>

<svelte:window on:unhandledrejection={ev => tracker.track(EventName.Error, {message: ev.reason?.message, name: ev.reason?.name})} on:click={ev => trackClick(ev.target)}/>
<svelte:head>
  <style src="../reteyn-components/style.css"></style>
</svelte:head>

<Main>
  <Router>
    <Route path="/{AppRoute.Submit}/:test/:answer" let:params>
      <Confirmed>
        <Submit
          {tracker}
          submitEndpoint={config.submitApiEndpoint}
          testId={params.test}
          answerId={params.answer}
        />
      </Confirmed>
    </Route>
    <Route path="/"><Home /></Route>
  </Router>
</Main>
