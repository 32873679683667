import { AnalyticsInstance, PageData } from "analytics";
import { EventName } from "./EventName.js";
import { TrackerTypes } from "./TrackerTypes.js";
import { Traits } from "./Traits.js";

export class Tracker {
  constructor(readonly instance: AnalyticsInstance) {}

  track<T extends EventName>(
    customEvent: EventName,
    properties: TrackerTypes[T],
  ): Promise<any> {
    return this.instance.track(customEvent, properties);
  }

  page(pageData?: PageData, options?: any): Promise<any> {
    return this.instance.page(pageData, options);
  }

  identify(userId: string, traits: Traits, options?: any): Promise<any> {
    return this.instance.identify(userId, traits, options);
  }
}
