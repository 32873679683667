<script lang="ts">
    import { onMount } from "svelte";
    import { resolveHomeSite } from "../config";

    onMount(() => {
        const homeSite = resolveHomeSite();
        if (location.origin !== homeSite) {
            window.location.replace(homeSite);
        }
    });
</script>
