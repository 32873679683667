import { InputType } from "../dao/index.js";
import { ModelName } from "../schema/index.js";

export const gifs: InputType<ModelName.Gif>[] = [
  { gid: "14sy6VGAd4BdKM", correct: true },
  { gid: "1ZkMDj88mQ1rO", correct: true },
  { gid: "26FPnsRww5DbqoPuM", correct: true },
  { gid: "26gsvAm8UPaczzXz2", correct: true },
  { gid: "3o6ZsT6WxOY4eZw8fK", correct: true },
  { gid: "3o7TKGy6TBUPrjtQLC", correct: true },
  { gid: "3oEduVObV0nnnTSDCg", correct: true },
  { gid: "3oz8xA3jxNGlMPI83m", correct: true },
  { gid: "3WCNY2RhcmnwGbKbCi", correct: true },
  { gid: "4qZgsEncxUdzEreaqf", correct: true },
  { gid: "4VUl7rVWLAYt90fLcH", correct: true },
  { gid: "5YngTgT41LkX6MgOnY", correct: true },
  { gid: "6JQ0wWi15NUG10ApMc", correct: true },
  { gid: "8i7IQbqY4iXuD3MDRT", correct: true },
  { gid: "8JxhhhQfBvEe4", correct: true },
  { gid: "8vpeyWA3OWOhG", correct: true },
  { gid: "aj1Osa9Wex6NvsTFST", correct: true },
  { gid: "ap6wcjRyi8HoA", correct: true },
  { gid: "AylNEiYGiIDg4", correct: true },
  { gid: "b5WsjNpMc35za", correct: true },
  { gid: "BnUTIXUGzKqSuEswob", correct: true },
  { gid: "bVcI9KeXnUzi6fsuj4", correct: true },
  { gid: "ByeYxURXxPyptxD2ta", correct: true },
  { gid: "cdNSp4L5vCU7aQrYnV", correct: true },
  { gid: "CvZuv5m5cKl8c", correct: true },
  { gid: "czubJ08i7deuKGJE9A", correct: true },
  { gid: "d2Z9QYzA2aidiWn6", correct: true },
  { gid: "d31x1fB3jEBvQ7E4", correct: true },
  { gid: "d4blihcFNkwE3fEI", correct: true },
  { gid: "dykJfX4dbM0Vy", correct: true },
  { gid: "f7BL4bNA0C0ECszmVa", correct: true },
  { gid: "FX3OLJAUhOZNK", correct: true },
  { gid: "GaLmbEfTi1p17tEdex", correct: true },
  { gid: "i0cixBOB8GVpqwVmDu", correct: true },
  { gid: "IOROMyERlykqwDrXEJ", correct: true },
  { gid: "JaqffrCpdCtxVqhTPI", correct: true },
  { gid: "JoSiL3L4ON8DlyVxv0", correct: true },
  { gid: "K0TTLjmaAmPsOQFodC", correct: true },
  { gid: "k2bbmbmvUo7gA", correct: true },
  { gid: "kisYcdllUagH6", correct: true },
  { gid: "kQdtQ8JIYFRuoywakC", correct: true },
  { gid: "KvGkTDzBnY03m", correct: true },
  { gid: "l0MYM7umPHrEq15S0", correct: true },
  { gid: "l2RsBhyCSu5Y5XU3Zf", correct: true },
  { gid: "l46Cd4qNHmnDI8Hks", correct: true },
  { gid: "lsr3605ZkSCpG", correct: true },
  { gid: "lYpOXbTyaTF60", correct: true },
  { gid: "m4jqqr0kXABqw5a4nb", correct: true },
  { gid: "mgqefqwSbToPe", correct: true },
  { gid: "mqwyAKjHz612w", correct: true },
  { gid: "MY74ISxEfT4E1FpggA", correct: true },
  { gid: "oobNzX5ICcRZC", correct: true },
  { gid: "OpvoAdhfHlVde", correct: true },
  { gid: "pNpONEEg3pLIQ", correct: true },
  { gid: "Q66ZEIpjEQddUOOKGW", correct: true },
  { gid: "qHho9D3nk3nS8", correct: true },
  { gid: "SXkztI7REnZSeip2Q3", correct: true },
  { gid: "u23zXEvNsIbfO", correct: true },
  { gid: "UAXK9VGoJTbdcPgmcJ", correct: true },
  { gid: "uiMIJMFYgRaAz5Pcb7", correct: true },
  { gid: "9Ai5dIk8xvBm0", correct: true },
  { gid: "Od0QRnzwRBYmDU3eEO", correct: true },
  { gid: "3ohzdIuqJoo8QdKlnW", correct: true },
  { gid: "hZfm9Pj95F9Mk", correct: true },
  { gid: "QuTOdlwvMl5lHKbpRC", correct: true },
  { gid: "26uTt19akcFxRFCy4", correct: true },
  { gid: "A4R8sdUG7G9TG", correct: true },
  { gid: "J1WyRIJrTeKs6R6VHL", correct: true },
  { gid: "vc0QOKXHm6GXbf7uSg", correct: true },
  { gid: "xHMIDAy1qkzNS", correct: true },
  { gid: "Xhxd8T0og4oKs", correct: true },
  { gid: "XIRIwHRO8N4IMJr7jd", correct: true },
  { gid: "XreQmk7ETCak0", correct: true },
  { gid: "Y3HM6vjoHzFqb4QiW3", correct: true },
  { gid: "yoJC2GnSClbPOkV0eA", correct: true },
  { gid: "Z6f7vzq3iP6Mw", correct: true },
  { gid: "zhIDyICDn75xm", correct: true },
  { gid: "H1vjPkqdL7liL6O7Bj", correct: true },
  { gid: "u6OJOz5nzSCFq", correct: true },
  { gid: "j2q3ptCUn2uCzC6yEa", correct: true },
  { gid: "LRNxdA0soqs09YWa4F", correct: true },
  { gid: "CjFtBhuEB5L0s", correct: true },
  { gid: "3o7abKhOpu0NwenH3O", correct: true },
  { gid: "3oEduKVQdG4c0JVPSo", correct: true },
  { gid: "OcZp0maz6ALok", correct: true },
  { gid: "TJgotk8MoedYFsvuYz", correct: true },
  { gid: "qC5VWY0keuqha", correct: true },
  { gid: "jR4p5oRNxG7sSLmKgb", correct: true },
  { gid: "MteOtn95GNx5e", correct: true },
  { gid: "bgOeHPlD3EernsOIiW", correct: true },
  { gid: "8lp6CW7K2fdDGn3xCQ", correct: true },
  { gid: "a4BJnTYeeJ3uKhGrnW", correct: true },
  { gid: "ENagATV1Gr9eg", correct: true },
  { gid: "LYDNZAzOqrez6", correct: true },
  { gid: "g9582DNuQppxC", correct: true },
  { gid: "3oEdv3Ul8g6BCngQ36", correct: true },
  { gid: "4Yn70mra350is", correct: true },
  { gid: "f7BO98VajAQXH2Y6a1", correct: true },
  { gid: "3o6UBhjHobLFgEmrJu", correct: true },
  { gid: "mGK1g88HZRa2FlKGbz", correct: true },
  { gid: "14bDMRUYVrzOIo", correct: true },
  { gid: "SUQzS3BEeUhLnIhLtD", correct: true },
  { gid: "ftMKOEsgL2ALMks8by", correct: true },
  { gid: "U56VoSyFD8MFcie2k8", correct: true },
  { gid: "EXHHMS9caoxAA", correct: false },
  { gid: "3osxYhufvkO5dz9jSo", correct: false },
  { gid: "hQKiGV6MG8WmsHg2yx", correct: false },
  { gid: "YTJXDIivNMPuNSMgc0", correct: false },
  { gid: "Dc1w8y69enroY", correct: false },
  { gid: "l4KibWpBGWchSqCRy", correct: false },
  { gid: "NQL7Wuo2JSQSY", correct: false },
  { gid: "11StaZ9Lj74oCY", correct: false },
  { gid: "JoDT2WaykzFnN9vJqL", correct: false },
  { gid: "3o6ozoD2BgEK8KNtfi", correct: false },
  { gid: "7JKo5eMDBxeErK38Fv", correct: false },
  { gid: "3o7ZesJdQccI0sO3fy", correct: false },
  { gid: "Ub8XEam5vXbMY", correct: false },
  { gid: "N35rW3vRNeaDC", correct: false },
  { gid: "UXSB8HYbpLQNq", correct: false },
  { gid: "g8CJl0epg71MQ", correct: false },
  { gid: "AAnIjZPUhrUM8", correct: false },
  { gid: "MQEBfbPco0fao", correct: false },
  { gid: "O34WuYSsXQL96", correct: false },
  { gid: "m2cwjSBcq67fi", correct: false },
  { gid: "S6GmIwq0c6zSAC7OYZ", correct: false },
  { gid: "Jk4ZT6R0OEUoM", correct: false },
  { gid: "YkNTiZHk51dfy", correct: false },
  { gid: "73h3LBWraONTW", correct: false },
  { gid: "u2TrRPK8J6Bwc", correct: false },
  { gid: "FewMZauWeGB0IpNpVO", correct: false },
  { gid: "3osxYwS1I1pxvgY8g0", correct: false },
  { gid: "LYd7VuYqXokv20CaEG", correct: false },
  { gid: "JNgLZn7fWAjjW", correct: false },
  { gid: "ak3bo8OYvrsac", correct: false },
  { gid: "26FPyr3ZL3j1r3I2s", correct: false },
  { gid: "3osxYmPBt9PMatfkl2", correct: false },
  { gid: "lOgzjLU2mmN3VoUG4S", correct: false },
  { gid: "O5pd2oPjjcGFW", correct: false },
  { gid: "KTZ8KtDGHhlLy", correct: false },
  { gid: "3o8doTsfXIV3ZTHA76", correct: false },
  { gid: "SjRgoSG1RTpYs", correct: false },
  { gid: "l2QZQPqIgeFAfZLDa", correct: false },
  { gid: "NDzYciTuZlOFy", correct: false },
  { gid: "pjzbionhFdccw", correct: false },
  { gid: "lb95bHRxh1Ze0", correct: false },
  { gid: "l4Ki5iMwlTt1ojWHC", correct: false },
  { gid: "iVhLISAEmtpFS", correct: false },
  { gid: "JKswczDIOEG2Y", correct: false },
  { gid: "najmtazy4OiiI", correct: false },
  { gid: "3og0IQvQkzyfxgjzLa", correct: false },
  { gid: "mJzayaTmOLKaQ", correct: false },
  { gid: "bU6GKBpWaJ4tO", correct: false },
  { gid: "lN9amhr8GZMhG", correct: false },
  { gid: "SVYrs1hU0SiAf1nw1n", correct: false },
  { gid: "JfLdIahamXQI0", correct: false },
  { gid: "KRB0DCpSFQeT6", correct: false },
  { gid: "IgQnL4Bu5UmHK", correct: false },
  { gid: "c3XM8SZ4g2Teg", correct: false },
  { gid: "N0gtPIqqPC1rO", correct: false },
  { gid: "jsIa0hyKnzhQRDYObr", correct: false },
  { gid: "NqZn5kPN8VVrW", correct: false },
  { gid: "2FaznsStYLpCNFhwQ", correct: false },
  { gid: "ZFZLIuWWkr29y", correct: false },
  { gid: "OOTtmh8oXrFK5ccNU7", correct: false },
  { gid: "wcpsMomEIgVCE", correct: false },
  { gid: "OpPLIgJYbdPBS", correct: false },
  { gid: "tP3Tu61F2RBZe", correct: false },
  { gid: "l46Ck8SQ1H26sRMkg", correct: false },
  { gid: "26tP3M3i03hoIYL6M", correct: false },
  { gid: "ZeRMA1DlxafrY42mFy", correct: false },
  { gid: "Pm08ZLlxa1mWttBOgt", correct: false },
  { gid: "Jrk7tpcTZtwcg", correct: false },
  { gid: "l41lWDDmyxtXnSkzm", correct: false },
  { gid: "fx2uQQZG8AuZ0MikCE", correct: false },
  { gid: "JrN1t3YgEOGJs6Fun5", correct: false },
  { gid: "xTcnTdM83DhRIhouRy", correct: false },
  { gid: "26tP41fh76vmLO3iU", correct: false },
  { gid: "KGTTNpVuGVhN6", correct: false },
  { gid: "l41lNncqO0ZinSArC", correct: false },
  { gid: "TfRuiiBj3mbHZuK05H", correct: false },
  { gid: "gk8kQjmYPo6mUCZWUJ", correct: false },
  { gid: "eLvpyAnUFTUtTjn5yP", correct: false },
  { gid: "WtJTo8989mhxxhRp7e", correct: false },
  { gid: "3oEhn5SBWY4gXf6xtm", correct: false },
  { gid: "YRUxpeVXq1lvFbrWQZ", correct: false },
  { gid: "8FEqe8OkOXmdW", correct: false },
  { gid: "pi2pWMN3oJLu8", correct: false },
  { gid: "11uLYV65cVRF72", correct: false },
  { gid: "3ov9k5dEvxf264kXKg", correct: false },
  { gid: "a0h7sAqON67nO", correct: true },
  { gid: "o75ajIFH0QnQC3nCeD", correct: true },
  { gid: "RKGmE7OioE7U08GvJD", correct: true },
  { gid: "4xpB3eE00FfBm", correct: true },
  { gid: "AgrfqPt5AyiTm", correct: true },
  { gid: "7kO9VZjCv3FkI", correct: true },
  { gid: "LFiOdYoOlEKac", correct: true },
  { gid: "uudzUtVcsLAoo", correct: true },
  { gid: "jpXAdNRiwGL0k", correct: true },
  { gid: "5oGIdt1xapQ76", correct: true },
  { gid: "xUA7bj4VO4OsWua8Du", correct: true },
  { gid: "aWRWTF27ilPzy", correct: true },
  { gid: "msKNSs8rmJ5m", correct: true },
  { gid: "s4VoCsFz8prlhSFCeS", correct: true },
  { gid: "l0ErFafpUCQTQFMSk", correct: true },
  { gid: "W0Vz7zDK7vgMBc2fpw", correct: true },
  { gid: "uLiEXaouJVkuA", correct: true },
  { gid: "b5L1Lt3k4hGNDZWVIw", correct: true },
  { gid: "xT9DPCJ1uw5uf56sGA", correct: true },
  { gid: "mHEes6Quf8XK0", correct: true },
  { gid: "QN6NnhbgfOpoI", correct: true },
  { gid: "WKdPOVCG5LPaM", correct: true },
  { gid: "S6l0TQr5lomVG", correct: true },
  { gid: "qVe56RCgGs6HRMF0Wl", correct: true },
  { gid: "98Nrl3nj3FaJG", correct: true },
  { gid: "11TU6Q1wz3UkWk", correct: true },
  { gid: "s4C4hRj7rAJCE", correct: true },
  { gid: "Aw2V3gGD5znfG", correct: true },
  { gid: "lrf5jEbnpVUek", correct: true },
  { gid: "l46CqLVMWzaJUFPLW", correct: true },
  { gid: "l0He7418Ko69i0VQ4", correct: true },
  { gid: "3ohfFNuB3FVwOxz0UU", correct: true },
  { gid: "wkA1l1lExztwk", correct: true },
  { gid: "l0MYMFnc1YYEi1a36", correct: true },
  { gid: "d3mmkM7o2qC78iZ2", correct: true },
  { gid: "3o7btSVA0BQdL1M6Ji", correct: true },
  { gid: "AiF8ZsTESrDwRjEcIU", correct: true },
  { gid: "6wo1zQtrtdsyEMwHjY", correct: true },
  { gid: "rSaQxzxmPAGpW", correct: true },
  { gid: "B4g6kQ3GX1YJ2", correct: true },
  { gid: "l0HlvcRyVJeO8Gmju", correct: true },
  { gid: "3o8doT9BL7dgtolp7O", correct: true },
  { gid: "10ERZqYioLWJ6U", correct: true },
  { gid: "26ufq9mryvc5HI27m", correct: true },
  { gid: "fRaBPZIlBGxmK4Y393", correct: true },
  { gid: "kPHBjnWpTtuDf3LC50", correct: true },
  { gid: "26BRBTPu9ruolhTqg", correct: true },
  { gid: "3oEdv3JFrkwGHnGdva", correct: true },
  { gid: "dpJrN4cttow8w", correct: true },
  { gid: "Uv8hpUW6D05ewzMaRb", correct: true },
  { gid: "3XsniO11VUbI2nrExk", correct: false },
  { gid: "CIspSkUM7KtDq", correct: false },
  { gid: "Q8CW9FaD0G3ggolUM0", correct: false },
  { gid: "qqSJWZi167ozu", correct: false },
  { gid: "26FPLsoMq5cPEVgg8", correct: false },
  { gid: "3osxY9wtJKHYl7BGhi", correct: false },
  { gid: "l4Ki8nHzJJvcquZHO", correct: false },
  { gid: "NpKR1QzAbwOyc", correct: false },
  { gid: "l2JJLi3mT98US529i", correct: false },
  { gid: "3oEduTLaSJK1huHxII", correct: false },
  { gid: "l41lJdcIw2TsOLN1m", correct: false },
  { gid: "3oEdv0FSl4mKhLNX5m", correct: false },
  { gid: "26tP3KaWsDnT47VMk", correct: false },
  { gid: "KmlTchPoFQT84", correct: false },
  { gid: "dGqajH1xpsGcw", correct: false },
  { gid: "14uzPzKMOuVIPu", correct: false },
  { gid: "3oEduNYqBMGd7c4wes", correct: false },
  { gid: "QsbnDuOAAl59C", correct: false },
  { gid: "rPacX3PmMzo52", correct: false },
  { gid: "3o7TKHW9XWLDiqJfva", correct: false },
  { gid: "5rxOiUZ4dq5Ww", correct: false },
  { gid: "Hqc4EOHR4gcRq", correct: false },
  { gid: "5eZ6ZGJQRLZio", correct: false },
  { gid: "CKGEH1DugpZyo", correct: false },
  { gid: "8SQwBWknR1Hos", correct: false },
  { gid: "3OWYIUc64PFXG", correct: false },
  { gid: "ELPtC7diADV2o", correct: false },
  { gid: "pwgwO5c3K0Q92", correct: false },
  { gid: "fAaBpMgGuyf96", correct: false },
  { gid: "mARLZArLIyNYA", correct: false },
  { gid: "l41lO6SPJpxOIvar6", correct: false },
  { gid: "hV1VFzPOWJ6ZplaCNd", correct: false },
  { gid: "26tP3M3i03hoIYL6M", correct: false },
  { gid: "nfLpqTrNPpqcE", correct: false },
  { gid: "l41YhMnxfsdWlMNmo", correct: false },
  { gid: "3xz2BtBTEaaYsqiW6k", correct: false },
  { gid: "d47JfoqwnL3tzvkk", correct: false },
  { gid: "LI7DRrJeN0dIk", correct: false },
  { gid: "12bHqjjfrmRmkU", correct: false },
  { gid: "l41lR9cLxFqcJI4co", correct: false },
  { gid: "d2Z5ilP4RxebTRpC", correct: false },
  { gid: "3oEdv5NkTodqPI8db2", correct: false },
  { gid: "26tn91maySpsZMvXG", correct: false },
  { gid: "l41lPLwLMcwSfC4ko", correct: false },
  { gid: "3oEduJRGNwwZ9gg7pS", correct: false },
  { gid: "d3MKhkGy2fKYbIg8", correct: false },
  { gid: "3o72ETIemIqVhejIsw", correct: false },
  { gid: "xTiTnhPv0H55AEvdBe", correct: false },
  { gid: "5yLgocgfdepMepTYzLy", correct: false },
  { gid: "3xz2BVXI0P8WCJqujK", correct: false },
];
