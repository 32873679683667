<script lang="ts">
    import { pickRandom } from "../dao";
    import { failEmojis } from "../dao/failEmojis";
    import { gifs } from "../dao/gifs";
    import { successEmojis } from "../dao/successEmojis";
    import { DisplayMode } from "./DisplayMode";
    import { preferencesStore } from "./preferencesStore";
    import Emoji from "./Emoji.svelte";
    import Gif from "./Gif.svelte";

    export let correct: boolean;

    $: emoji = pickRandom([...(correct ? successEmojis : failEmojis)]);
    $: gif = pickRandom(gifs.filter((g) => g.correct === correct));
</script>

<div class="flex w-full justify-center flex-col items-center gap-4">
    <div class="join">
        <input
            class="join-item btn rounded-btn"
            type="radio"
            name="displayMode"
            aria-label="Gif off"
            value={DisplayMode.Emoji}
            bind:group={$preferencesStore.displayMode}
        />
        <input
            class="join-item btn rounded-btn"
            type="radio"
            name="displayMode"
            aria-label="Gif on"
            value={DisplayMode.Gif}
            bind:group={$preferencesStore.displayMode}
        />
    </div>
    <div class="h-72 w-full flex justify-center">
        {#if $preferencesStore.displayMode === DisplayMode.Emoji}
            <Emoji symbol={emoji} class="text-8xl leading-[18rem]" />
        {:else}
            <Gif {gif} />
        {/if}
    </div>
</div>
