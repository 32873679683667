import { Config as PasswordlessConfig } from "amazon-cognito-passwordless-auth/config";
import { Config } from "../config";

export function toPasswordlessConfig(config: Config): PasswordlessConfig {
  const cognitoConfig = config.Auth?.Cognito;
  return {
    cognitoIdpEndpoint: config.API?.GraphQL?.region as string,
    userPoolId: cognitoConfig?.userPoolId,
    clientId: cognitoConfig?.userPoolClientId as string,
    debug: console.debug,
  };
}
