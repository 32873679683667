import { derived, Readable } from "svelte/store";
import { getTokens } from "../authentication";
import { TokensFromStorage } from "amazon-cognito-passwordless-auth/storage";
import { termsStore } from "./termsStore";

export const tokensStore: Readable<Promise<TokensFromStorage | undefined>> =
  derived(termsStore, async (terms) => {
    if (terms.confirmed) {
      return getTokens();
    }
  });
