import type {
  ListReturnValue,
  SingularReturnValue,
} from "@aws-amplify/data-schema-types";
import { parseResponse } from "./parseResponse.js";

export async function parseReturnValue<
  V extends SingularReturnValue<any> | ListReturnValue<any>,
>(response: V): Promise<Awaited<V>["data"]> {
  return parseResponse(await response);
}
