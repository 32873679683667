import App from "./App.svelte";
import { Amplify } from "aws-amplify";
import { resolveConfig } from "../config";
import { PasswordlessCredentialProvider } from "../authentication/index.js";

const config = await resolveConfig();
Amplify.configure(config, {
  Auth: {
    credentialsProvider: new PasswordlessCredentialProvider(),
  },
});

const app = new App({
  target: document.body,
  props: {
    config,
  },
});
export default app;
