import { persisted } from "svelte-persisted-store";
import { Writable } from "svelte/store";
import { DisplayMode } from "./DisplayMode";

const initialValue = {
  displayMode: DisplayMode.Gif,
};
export const preferencesStore = persisted("preferences", initialValue, {
  beforeRead: (v) => (v.displayMode ? v : initialValue),
}) as Writable<{ displayMode: DisplayMode }>;
