/**
 * List of SES events documented in https://docs.aws.amazon.com/ses/latest/dg/event-publishing-retrieving-sns-examples.html
 */
export enum EmailEventType {
  Bounce = "Bounce",
  Complaint = "Complaint",
  Delivery = "Delivery",
  Open = "Open",
  Send = "Send",
  Click = "Click",
  Reject = "Reject",
  DeliveryDelay = "DeliveryDelay",
  Subscription = "Subscription",
  RenderingFailure = "Rendering Failure",
}
