export enum CustomTypeName {
  SubmissionResponse = "SubmissionResponse",
  ReportTest = "ReportTest",
  ReportStudent = "ReportStudent",
  ReportReteyner = "ReportReteyner",
  ReportTopic = "ReportTopic",
  ReportQuestion = "ReportQuestion",
  ReportAnswer = "ReportAnswer",
  Role = "Role",
  QuestionOrder = "QuestionOrder",
  TestInfo = "TestInfo",
  TestAnswer = "TestAnswer",
  EmailType = "EmailType",
}
