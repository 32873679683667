<script lang="ts">
    import Emoji from "./Emoji.svelte";
    import { termsStore } from "./termsStore";
    const symbol = `🍪`;

</script>

{#if $termsStore.confirmed}
    <slot />
{:else}
    <div class="w-full flex flex-col items-center text-center gap-10">
        <Emoji {symbol} class="text-8xl" />
        <p>
            Our website runs on cookies, caffeine and good vibes. Click <span
                class="font-bold">'Accept'</span
            > to keep the good times rolling!
        </p>
        <button
            class="btn btn-primary"
            on:click={() =>
                termsStore.set({ confirmed: new Date().toISOString() })}
            >Accept</button
        >
    </div>
{/if}

<style>
    p {
        width: 80%;
    }
</style>
