import { LibraryOptions } from "@aws-amplify/core";
import { refreshTokens } from "amazon-cognito-passwordless-auth/refresh";
import {
  storeTokens,
  TokensToStore,
} from "amazon-cognito-passwordless-auth/storage";
import { jwtDecode } from "jwt-decode";
import { PasswordlessCredentialProvider } from "./PasswordlessCredentialProvider.js";
import { retrieveValidTokens } from "./retrieveValidTokens.js";

export const webLibraryOptions: LibraryOptions = {
  Auth: {
    credentialsProvider: new PasswordlessCredentialProvider(),
    tokenProvider: {
      async getTokens(request) {
        let tokens = await retrieveValidTokens();
        if (request && request.forceRefresh) {
          tokens = await refreshTokens({ tokens });
          await storeTokens(tokens as TokensToStore);
        }
        return tokens
          ? {
              accessToken: {
                payload: jwtDecode(tokens!.accessToken as string),
                toString() {
                  return tokens!.accessToken as string;
                },
              },
              idToken: {
                payload: jwtDecode(tokens!.idToken as string),
                toString() {
                  return tokens!.idToken as string;
                },
              },
            }
          : null;
      },
    },
  },
};
