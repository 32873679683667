import App from "./App.svelte";
import { Amplify } from "aws-amplify";
import { Passwordless } from "amazon-cognito-passwordless-auth";
import { resolveConfig } from "../config";
import { webLibraryOptions, toPasswordlessConfig } from "../authentication";

const config = await resolveConfig();
Amplify.configure(config, webLibraryOptions);
Passwordless.configure(toPasswordlessConfig(config));

const app = new App({
  target: document.body,
  props: {
    config,
  },
});
export default app;
