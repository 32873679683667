export class ApiClient<I, O> {
  constructor(private endpoint: string) {}

  async post(request: I): Promise<O> {
    const response = await fetch(this.endpoint, {
      method: "POST",
      body: JSON.stringify(request),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return response.json();
  }
}
