import { derived, Readable } from "svelte/store";
import { tokensStore } from "./tokensStore";
import { generateClient } from "aws-amplify/api";
import { ReteynSchema } from "../schema";
import { V6Client } from "@aws-amplify/api-graphql";

export const clientStore: Readable<Promise<V6Client<ReteynSchema>>> = derived(
  tokensStore,
  async (tokensPromise) => {
    const tokens = await tokensPromise;
    return generateClient<ReteynSchema>({
      authMode: tokens ? "userPool" : "identityPool",
    });
  },
);
